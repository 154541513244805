.contactUs{
    background-color: var(--bg-300);
}
.contact-business{
    text-align: center;
    color: var(--text-100);
    font-size: 25px;
    padding: 1rem 0;
}

.contact-form{
    display: flex;
    justify-content: center;
}

.form-perosnal{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
}

form label{
    color: var(--text-100);
    margin: 5px;
}

form input{
    width: 100%;
    padding: 5px;
    margin: 5px 0;
    border-radius: 6px;
}

form textarea{
    resize: none;
    height: 150px;
    margin: 5px;
    border-radius: 6px;
}
