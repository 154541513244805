.navBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bg-300);
  height: 80px;
  position: sticky;
  top: 0;
  z-index: 5;
  transition: all ease-in 0.3s;
}

.navBar::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.3);
  filter: blur(3px);
}

.navBar-logo img {
  width: 150px;
  background-size: contain;
  height: auto;
  margin-left: 1rem;
  cursor: pointer;
}

.nav-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-list ul {
  display: flex;
  align-items: center;
  margin: 0.5rem;
}

.nav-list ul a li {
  padding: 0 1rem;
  color: var(--text-100);
  font-weight: 600;
  font-size: 23px;
  border-right: 2px solid var(--accent-200);
  transition: ease-in-out 0.4s all;
}

.nav-list ul a li:hover {
  color: #bfbbbb;
}

.navBar-links {
  margin: 0 3px;
}

.nav-icon {
  width: 25px;
  height: 25px;
  padding: 8px 7px;
}

.icon-bar {
  width: 25px;
  height: 25px;
  margin-right: 1rem;
  cursor: pointer;
  display: none;
}

.icon-x{
    width: 25px;
    height: 25px;
    margin-right: 1rem;
    cursor: pointer;
    display: none;
} 

@media (max-width: 868px) {
  .nav-list {
    display: none;
    flex-direction: column;
    justify-content: start;
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--bg-300);
    width: 250px;
    height: 100vh;
    border-left: 1px solid var(--primary-300);
  }

  .nav-list ul {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-list .list-item{
    border-right: 2px solid transparent;
    padding-top: 1.5rem;
  }

  .nav-list.show {
    display: flex;
  }

  .icon-bar {
    display: block;
  }

  .icon-x{
    display: block;
    position: relative;
    top: 25px;
    left: 100px;
} 

  .navBar-links {
    margin-top: 1rem;
  }


}
