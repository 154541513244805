@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Exo 2", sans-serif;
}

a {
  text-decoration: none;
  color: var(--text-100);
}

ul li{
  list-style: none;
}

p,
span,
input {
  font-family: "Montserrat", sans-serif;
}

button,
.submit {
  background-color: transparent;
  border: 1px solid var(--accent-200);
  cursor: pointer;
  color: var(--text-100);
  padding: 0.5rem 2rem;
  letter-spacing: 2px;
}

.scan-button {
  overflow: hidden;
  transition: color 0.5s;
}

.scan-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 5%;
  height: 100%;
  background: rgba(255, 255, 255, 0.323);
  filter: blur(10px);
  transition: left 0.5s;
}

.scan-button:hover::before {
  left: 100%;
}

:root {
  --primary-100: #1e2022;
  --primary-200: #34373b;
  --primary-300: #1e1e72b1;
  --accent-100: #788189;
  --accent-200: #ffffff;
  --text-100: #f0f5f9;
  --text-200: #52616b;
  --bg-100: #f0f5f9;
  --bg-200: #232424;
  --bg-300: #000000;
}

.container {
  background-color: var(--bg-300);
}

.banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-image: fill 0 linear-gradient(#0001, #000);
}


.banner-ab {
  background-image: url("/public/assets/images/About/AboutUsBanner.jpg");
    background-size: cover;
  background-position: center;
}

.banner-og {
  background-image: url("/public/assets/images/OurGames/GamesBanner.png");
    background-size: cover;
  background-position: center;
}

.banner-os {
  background-image: url("/public/assets/images/OurServices/OurServices.png");
    background-size: cover;
  background-position: center;
}

.banner-co {
  background-image: url("/public/assets/images/Home/ContactUsBanner.jpg");
    background-size: cover;
  background-position: center;
}

.banner h2 {
  color: var(--text-100);
  text-shadow: 3px -1px 2px rgba(0, 0, 0, 0.6);
  font-size: 50px;
  letter-spacing: 1px;
}

.container-data {
  padding: 1.5rem;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 35px;
}

.item.flex-row {
  flex-direction: row;
}

.item.flex-row-reverse {
  flex-direction: row-reverse;
}

.item-image img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 600px;
  height: 350px;
  border-radius: 10px;
  filter: grayscale(100%);
  transition: ease-in-out 0.8s;
}

.item-image img:hover {
  filter: grayscale(0%);
  box-shadow: 4px 5px 5px -1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 4px 5px 5px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 5px 5px -1px rgba(0, 0, 0, 0.75);
}

.item-data {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.item-data p {
  color: var(--text-100);
  text-align: start;
  font-size: 20px;
  width: 520px;
}

.item-data p span{
  color: var(--primary-300);
  font-weight: 600;
  border-bottom: 1px solid var(--primary-300);
}

@media (max-width: 1200px) {
  .item-data p {
    font-size: 15px;
    width: 450px;
  }
  .item-image img {
    width: 450px;
    height: 275px;
  }
}

@media (max-width: 1024px) {
  .item-data p {
    font-size: 12px;
    width: 320px;
  }
  .item-image img {
    width: 375px;
    height: 250px;
  }
}

@media (max-width: 768px) {
  .banner {
    height: 300px;
  }
  .item-data p {
    font-size: 12px;
    width: 200px;
  }
  .item-image img {
    width: 300px;
    height: 200px;
  }
}

@media (max-width: 586px) {
  .item-data p {
    font-size:10px;
    width: 175px;
  }
  .item-image img {
    width: 200px;
    height: 170px;
  }
  .banner {
    height: 300px;
  }
}


@media (max-width: 450px) {
  .item {
    flex-wrap: wrap;
  }
  .banner h2 {
    color: var(--text-100);
    text-shadow: 3px -1px 2px rgba(0, 0, 0, 0.6);
    font-size: 35px;
    letter-spacing: 1px;
  }
}