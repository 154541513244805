.footer {
  background-color: var(--bg-300);
  color: var(--text-100);
  text-align: center;
  position: relative;
}

.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.3);
  filter: blur(3px);
}

.footer span {
  font-size: 8px;
  font-family: "Montserrat", sans-serif;
}

.footer-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 2rem 4rem;
}

.footer-emails h5,
.main-data h5 {
  font-size: 17px;
  font-family: "Exo 2", sans-serif;
}

.main-links {
  margin: 8px 0 0 3rem;
  width: 200px;
}
.footer-emails p {
  padding: 3px 0;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}

.footer-main img {
  width: 150px;
  height: auto;
}

.main-data p {
  width: 300px;
  padding: 1rem 0;
  font-size: 15px;
  font-family: "Exo 2", sans-serif;
}

.footer-policies p {
  font-family: "Montserrat", sans-serif;
  padding: 2px 0;
}


@media (max-width: 838px) {
  .footer-emails h5,
  .main-data h5 {
    font-size: 13px;
  }

  .footer-emails p,
  .main-data p {
    font-size: 12px;
  }

  .footer-policies p {
    font-size: 12px;
  }
}

@media (max-width: 650px) {
  .footer-container {
    flex-direction: column;
  }

  .footer-main {
    padding: 1.5rem;
  }
}
