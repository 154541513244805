.mainPage {
  background-color: var(--bg-300);
}

.mainVideo {
  width: 100%;
  object-fit: cover;
  height: 600px;
}

.main-aboutUs{
  background-color: #000000;
  background-image: linear-gradient(0deg, #000000 0%, #3c2049 100%);
  padding: 2rem;
}

.main-aboutUs button{
  margin: 20px 0;
}

.main-aboutUs h4,
.main-ourGames h4 {
  text-align: center;
  color: var(--text-100);
  padding: 4.5rem;
  font-size: 25px;
  font-family: "Montserrat", sans-serif;
}

.main-aboutUs h4 a {
  font-family: "Exo 2", sans-serif;
  color: var(--primary-300);
  font-weight: 700;
  font-size: 30px;
  text-shadow: 2px 1px 2px var(--accent-200);
}

.main-banner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-img {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 650px;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  padding-bottom: 5rem;
  border-image: fill 0 linear-gradient(#0001, #000);
}

.banner-img img {
  width: 500px;
}

.banner-img p {
  color: var(--text-100);
  font-size: 25px;
  font-family: "Exo 2", sans-serif;
  padding-bottom: 1.5rem;
}

.banner-game1 {
  background-image: url("/public/assets/images/Home/DeathRealmLogoBackground.jpg");
}

.banner-game2 {
  background-image: url("/public/assets/images/Home/TheHatchetManBackground.png");
}

.main-discord {
  text-align: center;
  padding: 3rem;
}

.main-discord h4 {
  color: var(--text-100);
  font-size: 30px;
  font-family: "Exo 2", sans-serif;
  padding: 1rem 0;
}

.main-ourGames {
  background-image: url("/public/assets/images/Home/SpecialBanner.jpg");
  height: 275px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.main-ourGames h4 {
  padding: 1rem 0;
}

.icon {
  width: 70px;
  height: 70px;
}

.icon-banner {
  width: 50px;
  height: 50px;
  padding: 0px 15px;
}

@media (max-width: 1000px){
  .banner-img {
    height: 450px;
  }

  .banner-img img {
    width: 300px;
  }

  .banner-img p {
    font-size: 20px;
  }
}

@media (max-width: 950px){
  .mainVideo{
    object-fit: contain;
  }
}

@media (max-width: 650px){
  .banner-img {
    height: 600px;
  }
  .banner-img img {
    width: 400px;
  }
  .main-banner{
    flex-direction: column;
  }
}


@media (max-width: 450px) {
  .banner-img img {
    width: 300px;
  }

  .main-aboutUs h4,
  .main-discord h4,
  .main-ourGames h4 {
    font-size: 20px;
  }
}