.policies{
    background-color: var(--bg-200);
    padding: 15px;
    color: var(--text-100);
    text-align: justify;
}

.policies .section{
    margin: 25px 0;
}

.section .sub-section{
    margin: 15px 15px 0;
}

.section span{
    margin-left: 5px;
}

.section p{
    margin-left: 10px;
    padding: 10px 0;
}

.section ul{
    margin-left: 20px;
}

.section ul li span{
    font-weight: 700;
    padding: 0 3px;
}

.policies h1{
    font-size:25px;
}
